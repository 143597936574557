/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



            var resizeTwoColHomeBlock = function() {
              if($('.two_column_settings').length > 0) {
                var windowW = $(window).width()+15;
                var baseH;
                var cardH1 = $('.two_column_settings').find('.inner-card-1').innerHeight();
                var cardH2 = $('.two_column_settings').find('.inner-card-2').innerHeight();

                if(cardH1 > cardH2){
                  baseH = cardH1;
                }
                else {
                  baseH = cardH2;
                }


                if( windowW >= 768 && windowW <= 1200) {
                  $('.two_column_settings').find('.inner-card').css('min-height', baseH);
                }
                else {
                  $('.two_column_settings').find('.inner-card').css('min-height', 1);
                }
              }
            };


            resizeTwoColHomeBlock();

        $(".text-limit").dotdotdot({
          watch: true,
          ellipsis  : '... ',
          after: 'a.read-more',
        });

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          e.preventDefault();
          var target = $(e.target).attr("href"); // activated tab
        });

        $('.search-btn').on('click', function(){
            $('.search-form').toggleClass('active');
            $(this).toggleClass('active');
        });

        $('.menu-close-btn').on('click', function(){
          $(this).toggleClass('active');
          $(this).siblings('.nav-primary').toggle();
        });

        $('.slick-xs').each(function(){

          $slick_slider = $(this);
          settings = {
            fade: true,
            dots: false,
            arrows: true
          };


          if ($(window).width() <= 768) {
            $slick_slider.slick(settings);
          }

          // reslick only if it's not slick()
          $(window).on('resize', function() {
            if ($(window).width() > 768) {
              if ($slick_slider.hasClass('slick-initialized')) {
                $slick_slider.slick('unslick');
              }
              return;
            }

            if (!$slick_slider.hasClass('slick-initialized')) {
              return $slick_slider.slick(settings);
            }
          });
        });

        $('.menu-item-has-children').on('click', function(){
          $(this).toggleClass('active');
        });

        var hash = $.trim( window.location.hash );

        if(hash){
          $(hash).trigger('click');
        }

        $(window).on('resize', function(){
           resizeTwoColHomeBlock();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
